<template>
  <v-navigation-drawer
    clipped
    expand-on-hover
    fixed
    :mobile-breakpoint="1"
  >
    <!-- profile info    -->
    <v-list
      class="mt-16 pt-16"
      dense
      nav
      dar
    >
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">
            Admin
          </v-list-item-title>
          <v-list-item-subtitle>me@pygeorge.com</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <!--    navigation-->
    <v-list>
      <v-list-item
        v-for="(route, i) in routes"
        :key="`nav-router_${i}`"
        :to="route.link"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ route.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ route.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'DefaultLayoutSideBar',
    data () {
      return {
        routes: [
          {
            title: 'Credentials',
            link: '/credentials',
            icon: 'mdi-account-group-outline',
          },
          {
            title: 'Workers',
            link: '/workers',
            icon: 'mdi-desktop-classic',
          },
          {
            title: 'Proxy',
            link: '/proxy',
            icon: 'mdi-account-tie-voice',
          },
          {
            title: 'Apollo clients',
            link: '/apollo/apollo-clients',
            icon: 'mdi-account-cash-outline',
          },
          {
            title: 'Apollo Tasks',
            link: '/apollo/apollo-tasks',
            icon: 'mdi-account-tie-voice',
          },

          {
            title: 'Emails',
            link: '/emails',
            icon: 'mdi-email-search-outline',
          },
          {
            title: 'Emails workflow',
            link: '/emails-workflow',
            icon: 'mdi-email-search-outline',
          },

          {
            title: 'Tasks',
            link: '/tasks',
            icon: 'mdi-file-tree',
          },
          {
            title: 'Schedule Tasks',
            link: '/schedule',
            icon: 'mdi-file-tree',
          },

          // {
          //   title: 'Problems',
          //   link: '/problems',
          //   icon: 'mdi-cogs',
          // },
          {
            title: 'Create task',
            link: '/tasks/create',
            icon: 'mdi-plus',
          },
        ],
      };
    },
  };
</script>

<style scoped>

</style>
